import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  useMultiStyleConfig
} from '@chakra-ui/react';
import React, { useState } from 'react';
import AddIcon from '@components/atoms/icons/AddIcon';
import MinusIcon from '@components/atoms/icons/MinusIcon';
import dataLayerPush from '@helpers/dataLayerPush';
import { stringToId } from '@helpers/stringFormatters';

const AccordionItemContent = ({ isExpanded, title, text, index }) => {
  const styles = useMultiStyleConfig(`faqItems`);

  return (
    <>
      <AccordionButton
        id={`${stringToId(title)}-${index}-accordion-button`}
        display="flex"
        px="0"
        py="0"
        textAlign="left"
        alignItems="flex-start"
        _hover={{ bgColor: `unset` }}>
        <Box
          as="h4"
          mr="4"
          pt="6"
          width="100%"
          borderTopStyle="solid"
          borderTopWidth={index > 0 ? `1px` : null}
          __css={styles.heading}>
          {title}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="14"
          h="14"
          mt="5"
          cursor="pointer"
          __css={styles.icon}>
          {isExpanded ? <MinusIcon w="6" h="6" /> : <AddIcon w="6" h="6" />}
        </Box>
      </AccordionButton>
      <AccordionPanel
        id={`${stringToId(title)}-${index}-accordion-panel`}
        pl="0"
        pr="20"
        py="0"
        fontSize={[`sm`, `md`]}>
        {text}
      </AccordionPanel>
    </>
  );
};

const FAQItems = ({ items, dark = false }) => {
  const [accordionIndex, setAccordionIndex] = useState(-1);
  const handleClick = (index, item) => {
    setAccordionIndex(accordionIndex === index ? -1 : index);
    // TODO Flesh out the datalayer push
    dataLayerPush({
      event: `accordion_click`,
      interaction: {
        accordion_title: item.title
      }
    });
  };

  return (
    <Accordion allowToggle index={accordionIndex} id="faq-items-accordion">
      {items.map((item, index) => (
        <AccordionItem
          id={`${stringToId(item.title)}-${index}-accordion-item`}
          key={item.title}
          border="none"
          mb="6"
          onClick={() => handleClick(index, item)}>
          <AccordionItemContent
            index={index}
            {...item}
            isExpanded={accordionIndex === index}
          />
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQItems;
